:root {
  --degreeSpin:360deg;
}
.App {
  font-family: sans-serif;
  text-align: center;

}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(var(--degreeSpin));
  }
}
.App1 {
  font-family: sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  block-size: 700px;

  /*
  display:inline-block
  margin-left: 605px;
  margin-bottom:100px;
  margin-top: -30px;
  block-size: 700px;*/
}
@media (prefers-reduced-motion: no-preference) {
  .zach {
    animation: App-logo-spin 1s forwards linear;
    display:flex;
    justify-content: center;
    align-items: center;
    block-size: 700px;
    /*display: inline-block;
    margin-left: 605px;
    margin-bottom:100px;
    margin-top: -10px;
    block-size: 700px;*/


  }
}

.spinner{

}

/*.ruth{
  display: inline-block;
  margin-left:-1250px;
  white-space: nowrap;

}
*/


.text1{
  font-size: 3em;
  font-family: verdana;
  font-weight: bold;
  background: black;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;

}
.text2{
  font-size: 15px;
  font-family: verdana;
  font-weight: bold;
  background: darkblue;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;

}
.text3{
  font-size: 30px;
  font-family: verdana;
  font-weight: bold;
  background: darkblue;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  margin-top:-20px;
  margin-botton: 30px;

}
.arrow{
  margin-top:-10px;
  margin-bottom: 0px;
}

.btn {
  text-decoration: none;
  padding: 10px 50px;
  font-size: 1.25rem;
  position: relative;
  margin: 30px;
  margin-top:0px;

}

.btn-3 {
  overflow: hidden;
  color: white;
  border-radius: 30px;
  box-shadow: 0 0 0 0 black, 0 0 0 0 darkblue;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  z-index: 0;
}

.btn-3::after {
  content: "";
  width: 400px;
  height: 400px;
  position: absolute;
  top: -50px;
  left: -100px;
  background-color: black;
  background-image: linear-gradient(
          225deg,
          black 0%,
          lightslategray 50%,
          darkblue 100%
  );
  z-index: -1;
  transition: transform 0.5s ease;
}

.btn-3:hover {
  transform: translate(0, -6px);
  box-shadow: 10px -10px 25px 0 darkblue,
  -10px 10px 25px 0 gray;
}

.btn-3:hover::after {
  transform: rotate(150deg);
}

body {
  background: linear-gradient(-45deg, gray, white, lightslategray, darkgray);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  height: 100vh;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}



